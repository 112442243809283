@mixin buttom-background($background) {
  background: $background;
  &:hover {
    background:darken($background,8%);
    transition: all 0.3s ease;
  }
  &:active {
    background:darken($background,25%);
  } 
}

@mixin responsive-ratio($x,$y, $pseudo: false) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
    //  @include responsive-ratio(16,9);
}

@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

// // Map with much breakpoints
// $breakpoints: (
//     "small": 320px,
//     "medium": 600px,
//     "large": 768px
// );
 
// // Respond-To Mixin
// @mixin respond-to($breakpoint) {
//     @if map-has-key($breakpoints, $breakpoint) {
//         $value: map-get($breakpoints, $breakpoint);
         
//         @media screen and (min-width: $value) {
//             @content;
//         }
//     }
     
//     @warn "Unknown `#{$breakpoint}` in $breakpoints";
// }