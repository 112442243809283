//@import url('http://fonts.googleapis.com/css?family=Calibri:400,300,700,900');
//@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900');

/* fonts:
================================================================================= */
 
/* lato regular */
 
// @font-face {
// 	font-family: 'Lato';
// 	src: url('../fonts/lato/Lato-Regular.eot');
// 	src: url('../fonts/lato/Lato-Regular.eot?#iefix') format('embedded-opentype'),
// 		 url('../fonts/lato/Lato-Regular.woff2') format('woff2'),
// 		 url('../fonts/lato/Lato-Regular.woff') format('woff'),
// 		 url('../fonts/lato/Lato-Regular.ttf') format('truetype'),
// 		 url('../fonts/lato/Lato-Regular.svg#Lato_regularregular') format('svg');
// 	font-weight: 400;
// 	font-style: normal;
// }
 
 @font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato-Regular'), url(../fonts/lato/Lato-Regular.ttf) format('truetype');
}
/* Lato italic */
 
// @font-face {
// 	font-family: 'Lato';
// 	src: url('../fonts/lato/fonts/lato/Lato-Italic.eot');
// 	src: url('../fonts/lato/fonts/lato/Lato-Italic.eot?#iefix') format('embedded-opentype'),
// 		 url('../fonts/lato/fonts/lato/Lato-Italic.woff2') format('woff2'),
// 		 url('../fonts/lato/fonts/lato/Lato-Italic.woff') format('woff'),
// 		 url('../fonts/lato/fonts/lato/Lato-Italic.ttf') format('truetype'),
// 		 url('../fonts/lato/fonts/lato/Lato-Italic.svg#Lato_regularitalic') format('svg');
// 	font-weight: 400;
// 	font-style: italic;
// }

 @font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: local('Lato-Italic'), url(../fonts/lato/Lato-Italic.ttf) format('truetype');
}
 
/* Lato bold */
 
// @font-face {
// 	font-family: 'Lato';
// 	src: url('../fonts/lato/Lato-Bold.eot');
// 	src: url('../fonts/lato/Lato-Bold.eot?#iefix') format('embedded-opentype'),
// 		 url('../fonts/lato/Lato-Bold.woff2') format('woff2'),
// 		 url('../fonts/lato/Lato-Bold.woff') format('woff'),
// 		 url('../fonts/lato/Lato-Bold.ttf') format('truetype'),
// 		 url('../fonts/lato/Lato-Bold.svg#Latobold') format('svg');
// 	font-weight: 700;
// 	font-style: normal;
// }

 @font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato-Bold'), url(../fonts/lato/Lato-Bold.ttf) format('truetype');
}

//@import url('https://fonts.googleapis.com/css?family=Quicksand');
//@import url('https://fonts.googleapis.com/css?family=Merienda+One');
//@import url(https://fonts.googleapis.com/css?family=Kaushan+Script);

@font-face {
  font-family: 'Kaushan Script';
  //font-style: normal;
 // font-weight: 700;
  src: local('KaushanScript-Regular'), url(../fonts/kaushanscript/KaushanScript-Regular.ttf) format('truetype');
}



@import "variables";
@import "mixins";
@import "bootstrap";
@import "bootstrap/theme";
@import "theme";