@import "custom_variable";

/*
  Copyright: SR
  www.aptappsys.com
*/
html{
    padding: 0;
    margin: 0;
}

$bodybg: #c1bdba;
$formbg: #13232e;
$white: #ffffff;

$main: #1ab188;
$main-light: lighten($main,5%);
$main-dark: darken($main,5%);

$gray-light: #a0b3b0;
$gray: #ddd;


$theme-color: #13232e;
$theme-color-light: lighten($theme-color,5%);
$theme-color-dark: darken($theme-color,5%);

$primary: #2983C9;
$secondary: #FA7500;
$blue: #09104C;
$white: #fff;
$logo-color: #707700 ;
$black: #000;
$footer-bg: #222;
$title-bg:#00b896;
$grey: #ccceee;
$pink: #ed2553;

body{
    background: #fff;
    background: whitesmoke;
}

header{
    border-top: 4px solid $theme-color;
    .navbar{
      border-top-width:0;
   }

    nav.navbar-custom ul.navbar-nav a { 
        color: $white;
        text-transform: none;
        font-size: 16px !important;
        text-decoration: none;
        text-transform: uppercase;
    }

nav.navbar-custom ul.navbar-nav a:hover, nav.navbar-custom ul.navbar-nav a:focus { 
   	color: $white;
	text-decoration:none;
}


.navbar{
    margin-bottom: 0;
}

nav{     
     color: $black;
    .fa{
        margin-right: 5px;
        color: $black;
        padding:2px;
    }
     .navbar-header {
         img {
            max-height: 130px;
            padding: 5px 0 10px 0;
        }
     }
     .sec-nav{
         font-size: 1.2em;
         color: $black;
         float: right;
         padding-top: 10px;
         @media (max-width: 767px) {
            padding-top: 2px;
         }
         a[href^=tel] { 
            color: inherit; 
            text-decoration: none; 
        }
     }   
}

 @media (min-width: 768px) {
    .collapse.navbar-collapse .container-fluid {
        float:right;
    }

    nav.navbar-custom ul.navbar-nav li{
        padding: 5px 15px  5px 15px;
     }

    nav.navbar-custom ul.navbar-nav li:last-child {
         border-right: none;
    }

    nav.navbar-custom ul.navbar-nav.sec-nav li{
           border-right: none;
    }

}

 @media (max-width: 767px) {
    .collapse.navbar-collapse .nav.navbar-nav:first-child {
         margin-bottom: 0px;
    }
    .collapse.navbar-collapse .nav.navbar-nav:first-child li:last-child {
         margin-bottom: 0px;
    }
    .collapse.navbar-collapse .nav.navbar-nav:last-child li:first-child a {
        padding-top: 0px;
    }
    .navbar-header img {
        max-height:80px;
    }
    .collapse.navbar-collapse.in .nav.navbar-nav {
        float:none !important;
        display:block;
    }
    .sec-nav li{
        color: $black !important;
        border-bottom: 1px solid $black;
        .fa{
            margin-right: 2px;
        }
        a[href^=tel] { 
            color: inherit; 
            text-decoration: none; 
        }
    }

    #myNavbar br{
       display: none;
    }
  }


}

.custom-bar:before, .custom-bar:after {
        content:" ";
        display: table;
}
.custom-bar:after {
  clear: both;
}
.custom-bar {
  *zoom: 1;
}

.custom-bar {
  list-style:none;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}
.custom-bar > li {
  background:  $theme-color-light; 
  float: left;
  position: relative;
  border-right: 2px solid $white;
    @media (max-width: 767px) {
      width: 100%;
      border-right: 0;
      border-bottom: 1px solid $white;
    }
}


.custom-bar a {
  display: block;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
}
.custom-bar li:hover {
  background: $secondary;
}
.custom-bar > li > a {
  padding: 1em 1em;
}



#section-banner{
    width: 100%; 
    display: block;

   .owl-text-overlay {
    position: absolute;
    text-align: center;
    width: 60%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    //background-color: rgba(0, 0, 0, 0.4);
   // background: rgba(0, 0, 0, 0.4);
    padding-bottom: 20px;
    font-family: "Open Sans", sans-serif;
    border-radius: 15px 50px 30px 5px;
}
h2.owl-title {
    font-size: 35px;
    margin-bottom: 15px;
    font-family: "Kaushan Script", sans-serif;
    color: $white;
}
p.owl-caption {
    font-size: 26px;
    font-weight: bold;
    line-height: 24px;
   // text-transform: uppercase;
    text-align: center;
    color: $white;
    text-shadow: 1px 1px 0 rgba(0,0,0,.75);;
    padding-top: 10px;
    width: 100%;
}
.owl-theme .owl-controls .owl-page span:active {
    background-color: #fff !important;
}
}
.general-setting{
    float: left;
    padding: 50px 0 10px 0;
    width: 100%;
}


#section-about{
    @extend .general-setting;
    background-color: $white;
    padding-bottom: 20px;
    p{
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 24px;
      margin: 6px 0 !important;
      text-align: left;
    }
    ul {
      float: left;
      margin: 18px 0;
      padding: 0;
      width: 100%;
      li {
          background: rgba(0, 0, 0, 0) url("../images/blt.png") no-repeat scroll left center / 15px auto;
          float: left;
          font-size: 16px;
          font-weight: 400;
          list-style: outside none none;
          margin-bottom: 7px;
          padding-left: 29px !important;
          width: 100%;
      }
    }

    .read_more {
         background: $theme-color none repeat scroll 0 0;
         color: #ffffff;
         font-size: 17px;
         padding: 11px 33px 11px 33px;
         text-transform: uppercase;
    }
}

#section-product{
    background-color: #fff;
    padding-bottom: 20px;
    h2{
       font-weight:600;
       font-size:30px;
	   margin-top:20px;      
       text-transform: uppercase;
        &:after{
            border-bottom: 1px solid #36404b;
            content: "";
            display: block;
            width: 100%;
            margin: 5px 0;
        }
     } 
     .more-link{
        text-align: center;
        padding-top: 50%;
        a {
            line-height: 4.5em;
            padding: 0 0 0 1em;
            margin-bottom: 1em;
            outline: none;
            display: inline-block;
            background: $theme-color;
            border: 0;           
            text-decoration: none;
            color: $white;            
            .arr {
              background: $secondary;
              color: white;
              font-size: 1em;
              width: 3em;
              transition: margin 200ms;
              display: inline-block;
              line-height: 4.5em;
              text-align: center;              
              margin-left: .95em;
            }
            
            &:hover {
              background-color: $theme-color-light;
              .arr {
                margin-left: 2em;
                background-color: darken($secondary,5%);
              } 
            }
           
          }
    
     }

     
     .item-box {
        cursor: pointer;
        position: relative;
        z-index:2;
        background-color: #fff;
        margin: 25px 0px 0px 0px;
        border: 1px solid $gray;
        .item{
            position: relative;
            z-index:2;
            -webkit-transition: all 0.4s ease-out;
            -moz-transition: all 0.4s ease-out;  
            -o-transition: all 0.4s ease-out;  
            -ms-transition: all 0.4s ease-out;  
            transition: all 0.4s ease-out;
            
            &:hover {
               opacity: 0.6;
               -webkit-transition: all 0.4s ease-out;
               -moz-transition: all 0.4s ease-out; 
               -o-transition: all 0.4s ease-out;  
               -ms-transition: all 0.4s ease-out;  
               transition: all 0.4s ease-out;    
             }
    
            img{
                position: relative;
                z-index:2;
                display: block;
                margin: auto; 
               padding: 2px;              
            }               
        }
        .item-title {
            z-index:3;
            color: #fff;
            font-size:1.2em;
            padding: 5px 5px 5px 15px;
            background-color: $theme-color-light;
            text-transform: uppercase;
            a{
                text-decoration: none;
                color: $white;
            }
        }
    }
    
}

#section-quote.home-quote{
    margin: 0;
}

#section-quote{    
	background-color: $theme-color;;
    padding: 30px 35px;
    margin:30px 0 40px 0;
    
    #returnMsg{
        font-size:16px;
        font-weight:400;
        color:#FFF;
    }
   
    h4 {
        font-size:24px;
        font-weight:600;
        color:#FFF;
        text-align:center;
        text-transform: uppercase;
     }
    
    #mail {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    #message {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    input {
        cursor: text;
        padding: 0px 10px;
        width: 100%;
        height: 50px;
        background-color: transparent;
        border: 1px solid #FFF;
        font-size: 12px;
        color: #FFF;
        outline: none;
    }
    textarea {
        width: 100%;
        height: 100px;
        padding: 7px 10px;
        background-color: transparent;
        border: 1px solid $white;
        font-size: 12px;
        color: $white;
        outline: none;
    }

    .button {
        text-align: center;
        a {
            background-color: $white;
            padding: 10px 15px;
            font-size: 11px;
            text-transform: uppercase;
            color: #000;
            letter-spacing: 2px;
            display: inline-block;
            text-decoration:none;
            &:hover{
                background-color: $secondary;
            }
        }
    } 
    img{
       padding: 35px 0 0 25px;
    }    
}


footer{
    background: $theme-color-light;
    border-top: 4px solid $secondary; 
     
    p{
        margin: 0 auto;
        font-size: 1em;
        color: $white;
        margin-top: 30px;  
        text-align: center;  
        letter-spacing: 1px;
        text-transform: uppercase;
        //font-family: "Kaushan Script", sans-serif;
       // border-bottom: 1px solid $white;
       text-shadow: 1px 1px 0 rgba(0,0,0,.75);
    }

    .social
    {
         margin-top: 15px; 
         ul{
                text-align: center;
             li{
                 a{
                    display: inline-block;
                    width: 45px;
                    height: 45px;
                    line-height: 45px;
                    border-radius: 50%;
                    font-size: 16px;
                    color: $secondary;
                    border: 1px solid rgba(255, 255, 255, 0.3); 
                    text-align: center;                    
                    &:hover {
                        //border-color: $secondary;
                        background-color: $secondary;
                        color: $white;                        
                    }  
                 }
             }
         }
    }
    h2{     
        color: $secondary;
        font-size: 1.1em;
        font-weight: 700;
        margin-bottom: 10px;
        margin-top: 3px;
        text-transform: uppercase;
        border-bottom: 1px solid $secondary;
        padding-bottom: 5px;
        text-transform: uppercase;
    }
    address{
        color: $grey;
        line-height: 1.6em;
        font-size: 0.9em;
        text-transform: uppercase;
        .fa{
            padding-right: 3px;
            color: $grey;
            font-size: 18px;
        }        
    }
    
    .footer-bar{
        margin: 5px 0 5px 0;
        background: $theme-color-dark;
        padding: 7px 0;
        color: $grey;
            p{
               // text-align: left;
                font-size: 0.9em;
                margin:0;
                text-transform: lowercase;
            }
    }
   
}

#section-productin{
    background-color: $white;
    h1{
        font-size: 1.5em;
        font-weight: 700;
        text-transform: uppercase;
        color: $secondary;
        text-shadow: -2px -1px 1px $white, 1px 2px 2px rgba(0, 0, 0, 0.3);
    }    
    .product-box {
        width:100%;
        min-height: 250px;
        display: block;
        margin: 1em 0;
        border-radius: 5px;
        overflow: hidden;
        cursor: pointer;
        box-shadow: 0px 0px 21px 3px rgba(0,0,0,0.15);
        transition: all .1s ease-in-out;
        &:hover {
          box-shadow: 0px 0px 21px 3px rgba(0,0,0,0.11);
        }
        h1 {
            font-size: 1.5em;
            padding: .2em 0 .2em 1em;
        }

        h2 {
            font-size: 1.3em;
            font-weight: 600;
            text-transform: uppercase;
            border-left: 5px solid $primary;
            border-bottom: 1px solid $gray;
            margin: 0 0 10px;
            padding: 10px 0 10px 40px;
            color: $primary;                  
            text-transform: uppercase;
        }

        h3{
            font-size: 1.3em;
            font-weight: 400;
            margin-bottom: 5px;
            text-transform: uppercase;
            padding: .2em 0 .2em 1em;
        }
        .img-wrapper{
            padding: 3px;
            img{
                margin: 0 auto;
            }
        }
        .product-data {
            background: #fff;
            //flex: 3;
            padding: .2em .9em .5em 1em;            
            p {
              color: #636363;
              font-size: 1em;
              letter-spacing: 1px;
              font-weight: 300;
            }
            ul li {
              color: #636363;
              font-size: 17px;
              margin-left: 0;
            }           
        }
    }
}

#section-breadcrumb{
  background-color: $white;

.bread-crumbs{
    margin-left: 0;
    ul{
        padding: 15px 0 15px 0;
        li{
            list-style-type:none;
            &:first-child{
                a {
                  border-top-left-radius: 2px;
                  border-bottom-left-radius: 2px;
                  padding-left: 26px;
                  &:before {
                      display: none; 
                  }
                }
            } 
            &:last-child{
                a {
                    padding-right: 26px;
                    border-top-right-radius: 2px;
                    border-bottom-right-radius: 2px;
                    &:after{
                        display: none; 
                    }
                }
            } 
            a {
                float: left;
                height: 36px;
                background: #243038;
                text-align: center;
                padding: 0 20px 0 30px;
                position: relative;
                margin: 0 4px 0 0; 
                font-size: 13px;
                font-weight: bold;
                letter-spacing : 1.04px;
                text-transform : uppercase;
                line-height:38px;
                text-decoration: none;
                color: #F0E6E6;
                box-shadow: 0 1px 0px rgba(255,255,255,.2) inset,
                            0 -1px 0px rgba(0,0,0,.2) inset,
                            0 -1px 0px rgba(0,0,0,.1),
                            0 1px 0px rgba(255,255,255,.1);
                transition: 500ms;
              
              &:after {
                    content: "";  
                    border-top: 18px solid transparent;
                    border-bottom: 18px solid transparent;
                    border-left: 8px solid #243038;
                    position: absolute; right: -8px; top: 0;
                    z-index: 1;
                    transition: 500ms;
               }
               &:before {
                    content: "";  
                    border-top: 18px solid transparent;
                    border-bottom: 18px solid transparent;
                    border-left: 8px solid #394B57;
                    position: absolute; left: 0; top: 0;
              }
            
            }
        }
    }
    
}
}

#section-aboutin {
    background-color: #FFF;
    h2 {
        font-size:30px;
        font-weight:600;
        margin-top:25px;
        margin-bottom:20px;
        &:after {
            border-bottom: 1px solid #36404b;
              content: "";
              display: block;
              width: 100%;
              margin: 5px 0;
          }
    }
    p {
        font-size:17px;
        line-height:30px;
        text-align:justify;
    }
  }
 
  .e-mail:before {
    content: attr(data-website) "\0040" attr(data-user);
    unicode-bidi: bidi-override;
    direction: rtl;
}

#section-contactin{
    background-color: $white;
    .address-box{
        min-height: 150px;
        background-color: #f7f5fb;
        padding: 15px 17px 15px 17px;
        margin: 30px 0 20px 0px;
        .fa {
            font-size: 20px;
            color: $primary;
            padding: 3px;
        }
        h4{
            text-transform: uppercase;
            text-align: left;
            border-bottom: 1px solid #F0E6E6;
        }
        ul{
            font-size: 400 13px/1.7;
            
            li{
                label{
                    font-weight: bold;
                    min-width: 55px;
                    border-bottom: 1px solid #F0E6E6;
                    padding-right: 2px;
                }
            }
        }
    }
}

.contact-sidebar{
    color: $theme-color-dark;
   h3{
        text-transform: uppercase;
        text-align: left;    
        border-bottom: 1px solid $theme-color-dark;
        font-size: 1.4em;
        //line-height: 1.5px;
   }   
   .detail{
       font-size: 1.1em;
       .fa{
           padding: 12px;
           margin-right: 5px;
           border: 1px solid $gray;
           border-radius: 50%;
           color: $primary;
       }
   }

}

.min100{
    min-height: 100px;
}
.min125{
    min-height: 125px;
}
.min150{
    min-height: 150px;
}
.min175{
    min-height: 175px;
}
.min200{
    min-height: 200px;
}
.min225{
    min-height: 225px;
}

.img-container{
    padding: 25px 5px 10px 25px;
    margin: 0 auto;
}
