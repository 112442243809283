$main-color: #36404b;
$white: #ffffff;
$slider-highlight-bg: #36404b;
$figure-h4-color: #36404b;
$slider-caption: #ffffff;

$prouct-figure-h4-a-bg: #00a8d5;
$section-about-li-tickbox: '/wp-content/themes/apptheme/assets/images/blt.png';
$section-contact-button-bg: #00a8d5; 
$section-contact-success-color: #FD3D50;
$footer-bg-color: #161616;
$footer-h2: #4EE2C0;
$footer-span: #00a8d5;


$h3-color: #9c9c9c;
$title-span-color: #00a8d5;    //  first line bar
$logo-bar-bg-color: #1F8A70;

$logo-bar-i: #ffffff;
$logo-bar-label: #ffffff;

$banner-bg-color:#1F8A70;
$banner-bg-image: '/wp-content/themes/aptalpha/assets/images/own/banner_bg.jpg';
$banner-color: #FCFAE1;
$banner-heading: #FCFAE1;
$banner-para: #FCFAE1;
$banner-quote-btn-bg: #FD7400 none repeat scroll 0 0;
$banner-quote-btn-color: #ffffff;

$nav-primary-color: #36404b;
$nav-secondary-color:#004358;// #046380;
$nav-primary-active-bg-color: #004358;

$nav-text-color: #000;
$nav-toggle-text-color: #000;
$nav-dropdown-text-color: #000;
$nav-text-hover-color: #A7A37E;

$nav-bg-hover-color: #36404b;
$nav-bg-toggle-color: #004358;

$nav-toggle-border-color: #EFECCA;
$nav-toggle-border-hover-color:#EFECCA;

$nav-dropdown-text-hover-color: #232020;

$nav-dropdown-hover-bg-color: #FFE11A;

$nav-brand-color: #FD7400;
$nav-brand-hover-color: #A7A37E;